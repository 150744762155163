.ant-dropdown {
  z-index: 1400;
}
/* ----------------------------------------------------- table ----------------------------------------------------- */
.cell_type_1 {
  font-size: 14px;
  font-weight: 500;
  // color: #333333;
}
.cell_type_2 {
  font-size: 14px;
  font-weight: 500;
}
.cell_type_3 {
  font-size: 12px;
  font-weight: 700;
}
.cell_long_text {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-tbody > tr > td {
  background: transparent;
  border: 4px solid transparent;
  padding: 0;
}
.ant-table-selection-column {
  background: transparent !important;
  border-radius: 3px;
  position: relative;
  // border: none !important;
  margin: 10px 0;
}
.ant-table-selection-column::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 10px #00000033;
}
.ant-table-tbody > tr > td[colspan] {
  background: transparent;
  border-radius: 5px;
  border: 4px solid transparent;
  padding: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent !important;
}
// td.ant-table-cell.ant-table-row-expand-icon-cell {
//   border-right: 0;
// }
// td.ant-table-cell.ant-table-row-expand-icon-cell + td.ant-table-cell {
//   border-left: 0;
// }
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}
// .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
//   color: rgba(0, 0, 0, 0.25);
// }
/* ----------------------------------------------------- inputs ----------------------------------------------------- */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-table-row-expand-icon-cell .expanded > div > svg rect {
  fill: #ff5939;
}
.ant-table-row-expand-icon-cell .nonExpanded > div > svg rect {
  fill: #f9b914;
}

// Select
.ant-select-selector {
  min-height: 40px;
}
.ant-select-arrow {
  top: 30%;
  margin-top: 0;
  height: 100%;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover,
.ant-form-item-has-error .ant-input-number:hover,
.ant-form-item-has-error .ant-picker :hover {
  background-color: transparent !important;
}
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent !important;
}

.ant-form-item-with-help.ant-form-item-has-error .birthday-error {
  border: 0.25px solid #ff5939 !important;
  background: transparent;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent;
  box-shadow: none;
}

.ant-form-item-has-error {
  input.form-control,
  .react-tel-input .form-control:focus {
    border: 0.25px solid #ff5939;
    background: transparent;
    transition: all 0.3s;
  }
  .react-tel-input .form-control:focus {
    background-color: transparent;
    box-shadow: 0 0 0 0.2rem #ff4d4f25;
  }
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder,
.ant-select-selection-item {
  display: flex;
  align-items: center;
}
.ant-select-selection-item {
  font-size: 14px;
  font-weight: 500;
}
.ant-select-selection-placeholder {
  font-size: 14px;
  font-weight: 600;
}
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 12px;
  font-weight: 500;
}

.ant-form-vertical {
  .ant-form-item-required {
    position: relative;
    width: fit-content;
    &::before {
      position: absolute;
      right: -20px;
      color: #e50707 !important;
      font-size: 18px !important;
    }
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent !important;
}

.ant-empty-description {
  color: #ffffff;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-affix-wrapper .ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-picker:hover,
.ant-picker-focused,
.react-tel-input .form-control:focus,
.react-tel-input .form-control:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #f9b914;
  background-color: transparent;
  box-shadow: none;
}

.ant-picker-suffix {
  color: #fff;
  transition: all 0.3s;
}

.ant-picker-input:hover .ant-picker-suffix {
  color: #f9b914;
}

.ant-picker-today-btn {
  color: #f9b914;
  font-weight: bold;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f9b914;
}
.ant-checkbox-checked::after {
  border-color: #f9b914;
}
.ant-input-affix-wrapper-disabled {
  border: none !important;
}
/* ----------------------------------------------------- checkbox ----------------------------------------------------- */
// .ant-checkbox-wrapper:hover .ant-checkbox-inner,
// .ant-checkbox:hover .ant-checkbox-inner,
// .ant-checkbox-input:focus + .ant-checkbox-inner {
//   border-color: #f9b914;
// }
// .ant-checkbox-checked::after {
//   border-color: #f9b914;
// }
// .ant-checkbox-checked .ant-checkbox-inner {
//   background-color: #f9b914;
//   border-color: #f9b914;
// }
// .ant-checkbox-indeterminate .ant-checkbox-inner::after {
//   background-color: #f9b914;
// }
// .ant-checkbox-checked .ant-checkbox-inner:hover {
//   background-color: "white";
// }

/* ----------------------------------------------------- modal ----------------------------------------------------- */
.ant-modal-mask {
  mix-blend-mode: normal;
  @supports ((backdrop-filter: none) or (-webkit-backdrop-filter: none)) {
    background: linear-gradient(
      90.73deg,
      rgba(216, 213, 213, 0.1) 0.34%,
      rgba(0, 0, 0, 0.1) 105.09%
    );
    backdrop-filter: blur(50px);
  }
}
/* ----------------------------------------------------- ToolTip ----------------------------------------------------- */

.ant-tooltip-arrow-content {
  background: rgba(0, 0, 0, 0.7) !important;
}
.ant-tooltip-inner {
  background: rgb(0 0 0 / 82%) !important;
}

/* ----------------------------------------------------- Dropdown ----------------------------------------------------- */

/* ----------------------------------------------------- Collapse ----------------------------------------------------- */
.ant-checkbox-inner {
  background: transparent;
  border-radius: 4px;
}
