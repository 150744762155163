#__react-alert__ {
  & > div {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100%;
    z-index: 1500 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: unset !important;
    pointer-events: unset !important;
    & > div {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 100% !important;
      height: 100%;
      transition: all 150ms ease-in-out 0s !important;
      background: #91a9b2;
      @supports ((backdrop-filter: none) or (-webkit-backdrop-filter: none)) {
        background: linear-gradient(
          90.73deg,
          rgba(121, 185, 199, 0.2) 0.34%,
          rgba(255, 255, 255, 0.2) 105.09%
        );
        backdrop-filter: blur(30px);
      }
    }
  }
}
