html,
body,
#root {
  min-height: 100vh;
}
/* ----------------------------------------------------- START FIT CONTENT ----------------------------------------------------- */

.fit-width {
  width: intrinsic; /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.fit-min-width {
  min-width: intrinsic; /* Safari/WebKit uses a non-standard name */
  min-width: -moz-max-content; /* Firefox/Gecko */
  min-width: -webkit-max-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.fit-height {
  height: intrinsic; /* Safari/WebKit uses a non-standard name */
  height: -moz-max-content; /* Firefox/Gecko */
  height: -webkit-max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.fit-size {
  @extend .fit-width;
  @extend .fit-height;
}

/* ----------------------------------------------------- END FIT CONTENT ----------------------------------------------------- */

/* ----------------------------------------------------- START CUSTOM SCROLLBAR ----------------------------------------------------- */

.custom-scrollbar {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #b1bdd1 #4d4d4d;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  border-radius: 3px;
  background-color: #4d4d4d;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #b1bdd1;
  background-color: #b1bdd1;
  border-radius: 3px;
}
/* ----------------------------------------------------- END CUSTOM SCROLLBAR ----------------------------------------------------- */

/* ----------------------------------------------------- START CUSTOM FLEX ----------------------------------------------------- */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------------------------------------------------- END CUSTOM FLEX ----------------------------------------------------- */

.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.wh100 {
  width: 100%;
  height: 100%;
}

/* ----------------------------------------------------- START CUSTOM BORDER ----------------------------------------------------- */

.rad-1 {
  border-radius: 1px;
}
.rad-2 {
  border-radius: 2px;
}
.rad-3 {
  border-radius: 3px;
}
.rad-4 {
  border-radius: 4px;
}
.rad-5 {
  border-radius: 5px;
}
.rad-6 {
  border-radius: 6px;
}
.rad-7 {
  border-radius: 7px;
}
.rad-8 {
  border-radius: 8px;
}
.rad-circle {
  border-radius: 100%;
}

/* ----------------------------------------------------- END CUSTOM BORDER ----------------------------------------------------- */

/* ----------------------------------------------------- START DRAG AND DROP ----------------------------------------------------- */

.ThirdlyUI-DragDrop-wrapper {
  display: flex;
  justify-content: unset;
  overflow: auto;
}
.ThirdlyUI-DragDrop-column {
  height: fit-content;
  background: rgba($color: #000000, $alpha: 0.3);
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 4px;
}
.DragDrop-items-wrapper {
  display: flex;
  flex-direction: column;
}
.ThirdlyUI-DragDrop-item {
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 12px;
}
@media (max-width: 960px) {
  .ThirdlyUI-DragDrop-wrapper {
    flex-direction: column;
  }
  .ThirdlyUI-DragDrop-column {
    //flex-direction: row;
    overflow: auto;
    width: 100vw !important;
    margin: 10px 0;
  }
}

/* ----------------------------------------------------- END DRAG AND DROP ----------------------------------------------------- */

/* ----------------------------------------------------- START CURSOR ----------------------------------------------------- */
.cursor-pointer {
  cursor: pointer;
}
/* ----------------------------------------------------- END CURSOR ----------------------------------------------------- */

// padded container
.paddedContainer,
.paddedContainer-fluid,
.paddedContainer-xxl,
.paddedContainer-xl,
.paddedContainer-lg,
.paddedContainer-md,
.paddedContainer-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .paddedContainer-sm,
  .paddedContainer {
    padding-left: calc(calc(100% - 540px) / 2);
    padding-right: calc(calc(100% - 540px) / 2);
  }
}
@media (min-width: 768px) {
  .paddedContainer-md,
  .paddedContainer-sm,
  .paddedContainer {
    padding-left: calc(calc(100% - 740px) / 2);
    padding-right: calc(calc(100% - 740px) / 2);
  }
}
@media (min-width: 992px) {
  .paddedContainer-lg,
  .paddedContainer-md,
  .paddedContainer-sm,
  .paddedContainer {
    padding-left: calc(calc(100% - 960px) / 2);
    padding-right: calc(calc(100% - 960px) / 2);
  }
}
@media (min-width: 1200px) {
  .paddedContainer-xl,
  .paddedContainer-lg,
  .paddedContainer-md,
  .paddedContainer-sm,
  .paddedContainer {
    padding-left: calc(calc(100% - 1140px) / 2);
    padding-right: calc(calc(100% - 1140px) / 2);
  }
}
@media (min-width: 1400px) {
  .paddedContainer-xxl,
  .paddedContainer-xl,
  .paddedContainer-lg,
  .paddedContainer-md,
  .paddedContainer-sm,
  .paddedContainer {
    padding-left: calc(calc(100% - 1320px) / 2);
    padding-right: calc(calc(100% - 1320px) / 2);
  }
}

/* ----------------------------------------------------- Svg Color Start ----------------------------------------------------- */
.svg-FF5939 path {
  fill: #ff5939;
}
.svg-FFFFFF path {
  fill: #ffffff;
}
/* ----------------------------------------------------- Svg Color End ----------------------------------------------------- */

/* ----------------------------------------------------- TEST Border ----------------------------------------------------- */
.b-red {
  border: 1px solid red;
}
.b-blue {
  border: 1px solid blue;
}
.b-white {
  border: 1px solid white;
}
.b-black {
  border: 1px solid black;
}

.bg-white-1 {
  background: rgba(255, 255, 255, 0.1);
}

/* ----------------------------------------------------- Center Toggle in responsive ----------------------------------------------------- */
.primary-tooltip-wrapper {
  @media (max-width: 576px) {
    display: grid;
    place-items: center;
  }
}

.icon-yellow-hover {
  cursor: pointer;
}

.icon-yellow-hover:hover path {
  fill: #f9b914;
}

/* ----------------------------------------------------- Paginate ----------------------------------------------------- */
.ThirdlyUI-table-pagination {
  font-size: 14px;
  font-weight: 500;
  color: #7e9cba;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 40px;
    & .ant-pagination {
      margin-top: 20px;
    }
  }
}
